import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import swal from 'sweetalert';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';

function Complain() {
    const [complain, setComplain] = useState({ name: "", number: "", text: '', complain_suggestion: 'NA', full_address: 'NA', street: '', property_name: '' ,full_address:''});
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'number') {
            if (complain.number.length === 0 && Number(value) < 6) {
                return;
            } else if (value.length > 10) {
                return;
            }
        }
        setComplain({ ...complain, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const res = await fetch('https://apis.ecowrap.in/b2g/eco-admin/complain', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(complain),
            });

            const response = await res.json();

            if (response.statusCode === 200) {
                setComplain({ name: "", number: "", text: '' });
                swal('Success', 'Complaint registered successfully.', 'success').then(() => navigate('/'));
            } else {
                swal('Oops', `${response.message}`, 'error');
            }
        } catch (error) {
            swal('Oops', 'Something went wrong.', 'error');
        }
    };

    return (
        <div>
            <Navbar />
            <div className="container mt-5">
                <div className="row justify-content-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
                    <div className="col-10 col-sm-7 col-md-5 col-lg-4">
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingName"
                                name="name"
                                value={complain.name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                            <label htmlFor="floatingName">Name</label>
                        </div>

                        <div className="form-floating mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="floatingNumber"
                                name="number"
                                value={complain.number}
                                onChange={handleChange}
                                placeholder="Enter your mobile number"
                                pattern="[6-9]{1}[0-9]{9}"
                                title="Mobile number should start with 6 or greater and be 10 digits long"
                                required
                            />
                            <label htmlFor="floatingNumber">Mobile Number</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingPropertyName"
                                name="property_name"
                                value={complain.property_name}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                            <label htmlFor="floatingPropertyName">house number/ business name</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingStreetName"
                                name="street"
                                value={complain.street}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                            <label htmlFor="floatingStreetName">street</label>
                        </div>
                        <div className="form-floating mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="floatingStreetName"
                                name="full_address"
                                value={complain.full_address}
                                onChange={handleChange}
                                placeholder="Enter your name"
                                required
                            />
                            <label htmlFor="floatingStreetName">full address</label>
                        </div>
                        <div className="form-floating mb-3">
                            <textarea
                                className="form-control"
                                id="floatingComplain"
                                name="text"
                                value={complain.text}
                                onChange={handleChange}
                                placeholder="Enter your text"
                                rows="3"
                                style={{ height: '200px' }}
                                required
                            />
                            <label htmlFor="floatingComplain">Suggestion</label>
                        </div>

                        <button
                            className="btn w-100 mb-2"
                            style={{ backgroundColor: '#228B22', borderColor: '#228B22', color: 'white' }}
                            onClick={handleSubmit}>
                            Submit
                        </button>
                        <div className=''>
                            <div>For further query</div>
                            <IconButton color="primary">
                                <PhoneIcon />
                            </IconButton>
                            <span>9269099901</span>
                        </div>

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Complain;
