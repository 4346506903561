import React, { useEffect, useState } from 'react';
import "./IECPerson.css";
import Spinner from './Spinner';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const IECPerson = () => {
    const navigate = useNavigate();
    const [userData, setuserData] = useState({
        name: '',
        email: '',
        number: '',
        locality: '',
        reference: '',
        license_number: '',
        vehicle_number: '',
        emr_contact_name: '',
        emr_contact_number: '',
        shirt_size: '',
        pf_number: '',
        guideline: false,
        joining_date: ''
    });
    const [imageData, setImageData] = useState([])
    const [imageData2, setImageData2] = useState({})
    const [loading, setLoading] = useState(false);

    const emailRegex = "/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g"
    const licenceRegex = "^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9][0-9])[0-9]{7}$"

    const changeHandle = (e) => {
        e.preventDefault();
        const { name, value, type, checked } = e.target;
        if (type === "checkbox") {
            setuserData({ ...userData, [name]: checked });
        } else {
            setuserData({ ...userData, [name]: value });
        }
    }

    const imageHandle = (e) => {
        const { name, files } = e.target;
        setImageData([...imageData, files[0]]);
        setImageData2({ ...imageData, name: files[0] });

    }

 



    const submitHandle = async (e) => {
        e.preventDefault();

        setLoading(true);

        const formData = new FormData();
        for (let key in userData) {
            formData.append(key, userData[key]);
            console.log();
        }

        Array.from(imageData).forEach((file, index) => {
            formData.append(`iec_person_doc`, file);
        });

        try {
            let response = await fetch('https://apis.ecowrap.in/website/iec-person', {
                method: 'POST',
                body: formData,
            });

            let result = await response.json();

            console.log('Success:', result);
            setLoading(false);
            if (result.statusCode == 200) {
                Swal.fire({
                    title: "Success",
                    text: "Your Information successfully Added",
                    icon: "success"
                });
                setuserData({ name: '', email: '', number: '', locality: '', reference: '', license_number: '', vehicle_number: '', emr_contact_name: '', emr_contact_number: '', shirt_size: '', pf_number: '', guideline: false, joining_date: '' });

                setImageData([]);
                setImageData2({ face_image: '', aadhar_card_front_image: '', aadhar_card_back_image: '', marksheet_12th: '', pan_card: '', bank_account: '' })

            } else {
                Swal.fire({
                    title: "Error ",
                    text: result.message,
                    icon: "error"
                });

            }

        } catch (error) {
            setLoading(false);
            alert("error: " + error)
            console.log(error);

        }



    };



    return (
        <div>
            {loading && < Spinner />}
            <div className='d-flex justify-content-center mt-5 w-100 main'>
                <form onSubmit={submitHandle} action='Submit' className='data_form form  d-flex flex-column gap-4 border p-3' style={{ minWidth: "300px" }}>
                    <div className='label_div'>
                        <label>Your Full Name (आपका पूरा नाम)</label>
                        <input name="name" value={userData.name} onChange={changeHandle} type='text' className='ps-2 py-1 form-control' placeholder='Name' required />
                    </div>

                    <div className='label_div'>
                        <label>Your Mobile Number (आपका मोबाइल नंबर)</label>
                        <input name="number" value={userData.number} onChange={changeHandle} type='tel' className='ps-2 py-1 form-control' placeholder='Phone Number' required />

                    </div>
                    <div className='label_div'>
                        <label>Email Address</label>
                        <input name="email" value={userData.email} onChange={changeHandle} type='email' className='ps-2 py-1 form-control' placeholder='Email' required />
                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Your Locality (Area Only):आपका क्षेत्र (केवल एरिया का नाम लिखें)</label>
                        <input name="locality" value={userData.locality} onChange={changeHandle} type='text' className='ps-2 py-1 form-control' placeholder='Your Locality(Area Only)' required />

                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Reference: Who referred you? (संदर्भ: किसने आपको रेफर किया?)
                        </label>
                        <input name="reference" value={userData.reference} onChange={changeHandle} type='text' className='ps-2 py-1 form-control' placeholder='Reference: Who Referred you?' required />

                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Your Driver's License Number(Optional)(आपका ड्राइविंग लाइसेंस नंबर)(वैकल्पिक)
                        </label>
                        <input name="license_number" value={userData.license_number} onChange={changeHandle} type='text' className='ps-2 py-1 form-control' placeholder='Driver Licence Number' />
                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Your Vehicle Number(Optional)(आपका वाहन नंबर)(वैकल्पिक)</label>
                        <input name="vehicle_number" value={userData.vehicle_number} onChange={changeHandle} type='text' className='ps-2 py-1 form-control' placeholder='Vehicle Number' />
                    </div>

                    <div className='label_div'>
                        <label className='text-start'>Emergency Contact Name:(आपातकालीन संपर्क व्यक्ति का नाम)
                        </label>
                        <input name="emr_contact_name" value={userData.emr_contact_name} onChange={changeHandle} type='text' className='ps-2 py-1 form-control' placeholder='Emergency Contact Name' required />
                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Emergency Contact Number: (आपातकालीन संपर्क नंबर)</label>
                        <input name="emr_contact_number" value={userData.emr_contact_number} onChange={changeHandle} type='tel' className='ps-2 py-1 form-control' placeholder='Emergency Contact Number' required />

                    </div>

                    <div className='label_div'>
                        <label className='text-start'>Upload Your Passport Size Photo (Face Image Only): (अपनी पासपोर्ट साइज फोटो अपलोड करें (केवल चेहरा दिखे))
                        </label>
                        <input name="face_image" value={imageData2.face_image} onChange={imageHandle} type='file' accept='image/*' required />
                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Upload Your Aadhaar Card (Front Image Only):आधार कार्ड अपलोड करें (सामने की तरफ वाली तस्वीर)</label>
                        <input name="aadhar_card_front_image" value={imageData2.aadhar_card_front_image} onChange={imageHandle} type='file' accept='image/*' required />

                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Upload Your Aadhaar Card (Back Image Only):आधार कार्ड अपलोड करें (पीछे की तरफ वाली तस्वीर)</label>
                        <input name="aadhar_card_back_image" value={imageData2.aadhar_card_back_image} onChange={imageHandle} type='file' accept='image/*' required />

                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Upload Your 12th Marksheet(PDF/Image) (अपनी 12वीं की मार्कशीट अपलोड करें (PDF/तस्वीर में))
                        </label>
                        <input name="marksheet_12th" value={imageData2.marksheet_12th} onChange={imageHandle} type='file' accept='image/*' required />
                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Upload Your Bank Account Passbook/Blank Cheque (अपना बैंक खाता पासबुक/चेक अपलोड करें)
                        </label>
                        <input name="bank_account" value={imageData2.bank_account} onChange={imageHandle} type='file' accept='image/*' required />

                    </div>
                    <div className='label_div'>
                        <label className='text-start'>Upload Your PAN Card (Optional) (पैन कार्ड अपलोड करें (वैकल्पिक):)</label>
                        <input name="pan_card" value={imageData2.pan_card} onChange={imageHandle} type='file' accept='image/*' />

                    </div>
                    <div className='label_div'>
                        <label className='text-start'>preferred T-shirt size: (कृपया अपनी पसंद का टी-शर्ट साइज चुनें)
                        </label>
                        <select value={userData.shirt_size} onChange={changeHandle} name='shirt_size' className='form-select' required>
                            <option value=''>Select Shirt Size</option>
                            <option value='S'>S</option>
                            <option value='M'>M</option>
                            <option value='L'>L</option>
                            <option value='XL'>XL</option>
                            <option value='XXL'>XXL</option>
                            <option value='XXXL'>XXXL</option>
                        </select>
                    </div>

                    <label className='text-start'>
                        If you have a Provident Fund (PF) number, please enter it below. If you do not have a PF number, you may leave this field blank. (यदि आपके पास प्रॉविडेंट फंड (PF) नंबर है, तो उसे यहाँ लिखें। अगर नहीं है, तो इस जगह को खाली छोड़ सकते हैं।)</label>
                    <input onChange={changeHandle} value={userData.pf_number} name="pf_number" type='text' />

                    <div className='d-flex gap-1 '>
                        <div>
                            <input onChange={changeHandle} value={userData.guideline} name='guideline' type='checkbox' checked={userData.guideline} />
                        </div>
                        <label className='text-start'>I agree to adhere to the guidelines and instructions provided by ECOWRAP during my employment period. (मैं ECOWRAP द्वारा दिए गए सभी निर्देशों और नियमों का पालन करने के लिए सहमत हूँ।)
                        </label>
                    </div>

                    <div className='label_div'>
                        <label className='text-start'>What is your Date of Joining? (आपकी जॉइनिंग की तारीख क्या है?)
                        </label>
                        <input name="joining_date" value={userData.joining_date} onChange={changeHandle} type='date' accept='image/*' required />

                    </div>

                    <button type='submit'>Submit</button>
                </form>
            </div>
        </div>
    );
}

export default IECPerson;
