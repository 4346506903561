import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import "./components.css";
import "./componentsMobile.css";
import "./pages.css";
import "./pagesMobile.css";

// Main website auth pages (auth)
import SendOtp from "./auth/SendOtp";
import VerifyLoginOtp from "./auth/VerifyLoginOtp";
import DeactiveOtp from "./auth/DeactiveOtp";
import VerifyDeactiveOtp from "./auth/VerifyDeactiveOtp";

// Main website pages (components)
import Home from "./pages/website/Home";
import Blog from "./pages/website/Blog";
import BlogDetails from "./pages/website/BlogDetails";
import BlogDetailsTrending from "./pages/website/BlogDetailsTrending";
import ClientsPartners from "./pages/website/ClientsPartners";
import AboutUs from "./pages/website/AboutUs";
import ContactUs from "./pages/website/ContactUs";
import IncentiveList from "./pages/website/IncentiveList";
import RecycleRight from "./pages/website/RecycleRight";
import RecycleRightDetails from "./pages/website/RecycleRightDetails";
import Bhayli from "./pages/website/Bhayli";
import AwardsAndMedia from "./pages/website/AwardsAndMedia";
import Faq from "./pages/website/Faq";
import RefundAndCancellationPolicy from "./pages/website/RefundAndCancellationPolicy";
import ShippingPolicy from "./pages/website/ShippingPolicy";
import PrivacyPolicy from "./pages/website/PrivacyPolicy";
import TermsAndConditions from "./pages/website/TermsAndConditions";
import Complain from "./pages/website/Complain";
import ViewWardMap from './pages/website/GeofenceMap'
// Dashboard website pages (pages)
import Dashboard from "./pages/dashboard/Dashboard";
import Impact from "./pages/dashboard/Impact";
import HowToEarn from "./pages/dashboard/HowToEarn";
import CurrentPickupDetail from "./pages/dashboard/CurrentPickupDetail";
import SchedulePickup from "./pages/dashboard/SchedulePickup";
import ApplyDustbins from "./pages/dashboard/ApplyDustbins";
import Wallet from "./pages/dashboard/Wallet";
import WalletHistory from "./pages/dashboard/WalletHistory";
import PickupHistory from "./pages/dashboard/PickupHistory";
import PickupHistoryDetails from "./pages/dashboard/PickupHistoryDetails";
import RateList from "./pages/dashboard/RateList";
import HelpAndSupport from "./pages/dashboard/HelpAndSupport";
import Profile from "./pages/dashboard/Profile";
import AddAddress from "./pages/dashboard/AddAddress";
import Blocked from "./pages/dashboard/Blocked";
import AddPropertyAndAddress from "./pages/dashboard/auth/AddPropertyAndAddress";
import AddPersonalInformation from "./pages/dashboard/auth/AddPersonalInformation";
import CheckRegistrationOtp from "./pages/dashboard/auth/CheckRegistrationOtp";
import VerifyRegistrationOtp from "./pages/dashboard/auth/VerifyRegistrationOtp";
import CheckRegistredProperty from "./pages/dashboard/auth/CheckRegistredProperty";
import Shop from "./shop/Shop";

import ErrorPage from "./ErrorPage";
import IECPerson from "./components/IECPerson";

const Router = () => {
    const apiUrl = process.env.REACT_APP_LIVE_URL;
    const apiDashboardUrl = process.env.REACT_APP_DASHBOARD_API;
    const apiUserUrl = process.env.REACT_APP_USER_API;

    const user_id = secureLocalStorage.getItem("UserId");
    const [userId, setUserId] = useState(user_id);
    const navigate = useNavigate();
    const location = useLocation();
    const title = "Waste Management Service Company | India's First Source Segregation Focus Start-up";

    useEffect(() => {
        const routesRequiringUserId = [
            "/dashboard",
            "/impact",
            "/current-pickup-details",
            "/schedule-pickup",
            "/apply-dustbins",
            "/wallet",
            "/wallet-history",
            "/pickup-history",
            "/profile",
            "/add-address",
        ];

        if (!userId && routesRequiringUserId.includes(location.pathname)) {
            navigate("/send-otp");
        }
    }, [userId, location.pathname, navigate]);


    return (
        <>
            <Routes>
                {/* Main website auth pages rendering (auth) */}
                <Route path="/send-otp" element={<SendOtp apiUserUrl={apiUserUrl} title={title} />} />
                <Route path="/verify-login-otp" element={<VerifyLoginOtp apiUserUrl={apiUserUrl} title={title} setUserId={setUserId} />} />
                <Route path="/deactive-otp" element={<DeactiveOtp apiUserUrl={apiUserUrl} title={title} />} />
                <Route path="/verify-deactive-otp" element={<VerifyDeactiveOtp apiUserUrl={apiUserUrl} title={title} />} />

                {/* Main website pages rendering (components) */}
                <Route path="/" element={<Home apiUrl={apiUrl} title={title} />} />
                <Route path="/blogs" element={<Blog apiUrl={apiUrl} title={title} />} />
                <Route path="/blog-details" element={<BlogDetails apiUrl={apiUrl} title={title} />} />
                <Route path="/trending-blog-details" element={<BlogDetailsTrending title={title} />} />
                <Route path="/clients-and-partners" element={<ClientsPartners apiUrl={apiUrl} title={title} />} />
                <Route path="/about-us" element={<AboutUs title={title} />} />
                <Route path="/contact-us" element={<ContactUs apiUrl={apiUrl} title={title} />} />
                <Route path="/incentive-list" element={<IncentiveList apiUrl={apiUrl} title={title} />} />
                <Route path="/recycle-right" element={<RecycleRight title={title} />} />
                <Route path="/recycle-right-details" element={<RecycleRightDetails title={title} />} />
                <Route path="/bhayli" element={<Bhayli title={title} />} />
                <Route path="/awards-and-media" element={<AwardsAndMedia title={title} />} />
                <Route path="/faq" element={<Faq apiUrl={apiUrl} title={title} />} />
                <Route path="/refund-and-cancellation-policy" element={<RefundAndCancellationPolicy title={title} />} />
                <Route path="/shipping-policy" element={<ShippingPolicy title={title} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy title={title} />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions title={title} />} />
                <Route path="/complain" element={<Complain title={title} />} />
                {/* Dashboard website pages render (pages) */}
                <Route path="/geoFenceView" element={<ViewWardMap title={title} />} />
                {/* Dashboard website pages render (pages) */}
                <Route path="/dashboard" element={<Dashboard apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/impact" element={<Impact apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/howToEarn" element={<HowToEarn title={title} />} />
                <Route path="/current-pickup-details" element={<CurrentPickupDetail apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/schedule-pickup" element={<SchedulePickup apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/apply-dustbins" element={<ApplyDustbins apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/wallet" element={<Wallet apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/wallet-history" element={<WalletHistory apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/pickup-history" element={<PickupHistory apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/pickup-history-details" element={<PickupHistoryDetails title={title} userId={userId} />} />
                <Route path="/rate-list" element={<RateList apiDashboardUrl={apiDashboardUrl} title={title} />} />
                <Route path="/help-and-support" element={<HelpAndSupport title={title} />} />
                <Route path="/profile" element={<Profile apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/add-address" element={<AddAddress apiDashboardUrl={apiDashboardUrl} userId={userId} title={title} />} />
                <Route path="/blocked" element={<Blocked title={title} />} />
                <Route path="/add-property-and-address" element={<AddPropertyAndAddress title={title} />} />
                <Route path="/add-personal-information" element={<AddPersonalInformation title={title} apiUserUrl={apiUserUrl} />} />
                <Route path="/check-registration-otp" element={<CheckRegistrationOtp apiUserUrl={apiUserUrl} title={title} />} />
                <Route path="/verify-registration-otp" element={<VerifyRegistrationOtp apiUserUrl={apiUserUrl} title={title} />} />
                <Route path="/check-registered-property" element={<CheckRegistredProperty title={title} />} />
                <Route path="/shop" element={<Shop title={title} />} />

                <Route path="*" element={<ErrorPage title={title} />} />
                 <Route path="/iecperson" element={<IECPerson/>} />

            </Routes>
        </>
    );
};

export default Router;
