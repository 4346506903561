import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import Navbar from './Navbar';
import Footer from './Footer';
import swal from 'sweetalert';

const ViewWardMap = ({ urlB2G }) => {
    const [polygons, setPolygons] = useState([]);
    const [mapInstance, setMapInstance] = useState(null);
    const [mapsInstance, setMapsInstance] = useState(null);
    const [polygonShapes, setPolygonShapes] = useState([]);
    const [userLocation, setUserLocation] = useState(null);
    const [center, setCenter] = useState({ lat: 26.8495, lng: 75.7575 });

    // Use ref to store the user marker instance
    const userMarkerRef = useRef(null);

    useEffect(() => {
        const fetchBoundaries = async () => {
            try {
                const response = await fetch(`https://apis.ecowrap.in/b2g/eco-admin/address/community/ward/geo-fance`, {
                    method: 'GET',
                });
                const data = await response.json();
                console.log(data);

                if (data?.status && data?.data?.length > 0) {
                    const polygonsData = data?.data.map((geofence) => ({
                        id: geofence?.geo_id,
                        bounds: geofence?.boundry[0]?.map(point => ({ lat: point?.x, lng: point?.y })),
                    }));
                    setPolygons(polygonsData);

                    // Center map on the first boundary's first point or default center
                    setCenter(polygonsData?.[0]?.bounds?.[0] || center);
                } else {
                    swal('Error', 'Something went wrong fetching boundary data.', 'error');
                }
            } catch (error) {
                console.error('error', error);
                swal('Error', 'Error fetching boundary data.', 'error');
            }
        };

        fetchBoundaries();

        // Watch user's live location
        if (navigator.geolocation) {
            navigator.geolocation.watchPosition((position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ lat: latitude, lng: longitude });
            });
        } else {
            swal('Error', 'Geolocation is not supported by this browser.', 'error');
        }
    }, [urlB2G]);

    useEffect(() => {
        if (mapInstance && mapsInstance) {
            // Remove previous polygons from the map
            polygonShapes.forEach(shape => shape.setMap(null));
            setPolygonShapes([]);

            const bounds = new mapsInstance.LatLngBounds();

            const newPolygonShapes = polygons.map((polygonData) => {
                const path = polygonData.bounds.map(point => {
                    const latLng = new mapsInstance.LatLng(point.lat, point.lng);
                    bounds.extend(latLng);  // Extend bounds to include each polygon point
                    return latLng;
                });

                const polygonShape = new mapsInstance.Polygon({
                    paths: path,
                    draggable: false,
                    editable: false,
                    fillColor: 'lightblue',  // Set boundary color to light blue
                    fillOpacity: 0.4,
                    strokeColor: 'blue',
                    strokeOpacity: 1,
                    strokeWeight: 2,
                });

                polygonShape.setMap(mapInstance);
                return polygonShape;
            });

            setPolygonShapes(newPolygonShapes);

            // Fit the map to the bounds of the polygon
            if (polygons.length > 0) {
                mapInstance.fitBounds(bounds);
            }
        }
    }, [polygons, mapInstance, mapsInstance]);

    useEffect(() => {
        if (mapInstance && mapsInstance && userLocation) {
            // Check if the marker already exists
            if (userMarkerRef.current) {
                // Update marker's position if it already exists
                userMarkerRef.current.setPosition(userLocation);
            } else {
                // Create a new marker if it doesn't exist
                userMarkerRef.current = new mapsInstance.Marker({
                    position: userLocation,
                    map: mapInstance,
                    icon: {
                        path: mapsInstance.SymbolPath.CIRCLE,
                        scale: 8,
                        fillColor: 'blue',
                        fillOpacity: 1,
                        strokeWeight: 0,
                    },
                });
            }

            // Center the map on the user's location
            mapInstance.panTo(userLocation);
        }
    }, [mapInstance, mapsInstance, userLocation]);

    return (
        <div>
            <Navbar />
            <div className="container mt-5">
                <div className="row justify-content-center" style={{ marginTop: '150px', marginBottom: '150px' }}>
                    <div className="col-12" style={{ height: '70vh', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: 'AIzaSyBqbSNw8jtcU9MjyqXFgKdP9l9UeWqaTz8' }} // Replace with the correct Google Maps API key
                            defaultCenter={center}
                            defaultZoom={14}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => {
                                setMapInstance(map);
                                setMapsInstance(maps);
                            }}
                            options={{
                                gestureHandling: 'greedy', // Enable pinch-to-zoom for mobile
                            }}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ViewWardMap;
